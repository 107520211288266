<template>
    <div class="h-screen flex w-full bg-img">
        <div class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
            <img src="../../assets/images/pages/maintenance-2.png" alt="graphic-maintenance" class="mx-auto mb-4">
            <h1 class="sm:mx-0 mx-4 mb-6 text-5xl d-theme-heading-color">You're Offline!</h1>
            <p class="sm:mx-0 mx-4 mb-6 d-theme-text-inverse text-primary">It seems you're not connected to internet. Please check your internet connection and try again.</p>
            <vs-button size="large" @click="redirectToRequest">Retry</vs-button>
        </div>
    </div>
</template>

<script>
export default {    
    methods: {
        redirectToRequest() {
            if(this.$route.params.next){
                this.$router.push(this.$route.params.next)
            } else {
                this.$router.push("/")
            }
        }
    }
}
</script>
